import { ref, watch, computed, onMounted } from "@vue/composition-api";
import router from "@/router";
import {
  resolveEvaluationStatus,
  resolveEvaluationText,
  resolveApproveText,
} from "@/libs/helper";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import i18n from "@/libs/i18n";
import { evaluationService } from "@/services/evaluation";
import dayjs from "dayjs";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const showCreateEvaluation = ref(false);

  const evaluationStatic = ref(null);
  const monthOptions = ref([]);
  const refUserListTable = ref(null);
  const allData = ref([]);
  const assessedUsers = ref([]);

  const selectMonth = ref(dayjs().format("YYYY-MM"));
  const selectFilter = ref("overall");
  // Table Handlers
  const tableColumns = [
    { key: "check", label: "", thStyle: { width: "1%" } },
    {
      key: "user",
      label: i18n.t("user"),
      sortable: false,
    },
    {
      key: "employee.department.name",
      label: i18n.t("department"),
      sortable: true,
    },
    { key: "status", label: i18n.t("status"), sortable: true },
    {
      key: "approveStatus",
      label: i18n.t("evaluations.status_approve"),
      sortable: true,
    },
    { key: "actions", label: i18n.t("evaluate_action") },
  ];

  const isLoading = ref(false);
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  const onClickSearch = () => {
    getAssessedUsers();
  };

  onMounted(() => {
    getEvaluationStatic();
    getMonth();
    getAssessedUsers();
  });

  watch([currentPage, perPage, selectMonth], () => {
    refetchData();
  });

  //เลือกแค่ tab เปลี่ยนแค่ user
  watch([selectFilter], () => {
    getAssessedUsers();
  });

  //เลือกเดือนต้องเปลี่ยนสถิติกับ user
  watch([selectMonth], () => {
    getEvaluationStatic();
    getAssessedUsers();
  });

  const buildDefaultParams = () => {
    const filter = {
      page: currentPage.value,
      size: perPage.value,
      currentDate: selectMonth.value,
      type: selectFilter.value,
      ...(searchQuery.value !== "" ? { searchQuery: searchQuery.value } : {}),
    };

    return new URLSearchParams(filter).toString();
  };

  const createEvaluation = async () => {
    isLoading.value = true;
    try {
      const respEvaluation = await evaluationService.createEvaluationPeriod();

      if (respEvaluation.status) {
        isLoading.value = false;
        getAssessedUsers();
        getEvaluationStatic();
        toast({
          component: ToastificationContent,
          props: {
            title: "สรัางรายการประเมินสำเร็จ",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      }
    } catch (e) {
      isLoading.value = false;
      toast({
        component: ToastificationContent,
        props: {
          title: "Error create evaluation",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };

  const evaluateEmployee = async (listID) => {
    try {
      const result = await evaluationService.evaluateEmployee({
        evaluationID: listID,
      });

      if (result.status) {
        getAssessedUsers();
        getEvaluationStatic();
      }
    } catch (e) {
      console.log(e);
      toast({
        component: ToastificationContent,
        props: {
          title: "Error evaluate employee",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };

  const getEvaluationStatic = async () => {
    try {
      const filter = {
        currentDate: selectMonth.value,
      };

      const queryParams = new URLSearchParams(filter).toString();

      const result = await evaluationService.getStatic(queryParams);

      if (result.status) {
        evaluationStatic.value = result.data;
      }
    } catch (e) {
      toast({
        component: ToastificationContent,
        props: {
          title: "Can't get static",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };

  const getMonth = async () => {
    try {
      const result = await evaluationService.getMonth();

      if (result.status) {
        monthOptions.value = result.data;
      }
    } catch (e) {
      toast({
        component: ToastificationContent,
        props: {
          title: "Can't get static",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };

  const getAssessedUsers = () => {
    const queryParams = buildDefaultParams();
    evaluationService
      .getAssessed(queryParams)
      .then((response) => {
        const { data, pagination, message } = response;

        if (data) {
          totalUsers.value = pagination.totalItems;
          assessedUsers.value = data;
          showCreateEvaluation.value = !(
            message === "EVALUATION_EMPLOYEE" &&
            selectMonth.value === dayjs().format("YYYY-MM")
          );
          allData.value = data.filter((item) => item.status === "empty");
        }

        showCreateEvaluation.value = message !== "EVALUATION_EMPLOYEE";
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    createEvaluation,
    assessedUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    refUserListTable,
    resolveEvaluationStatus,
    resolveEvaluationText,
    refetchData,

    // Extra Filters
    isLoading,
    evaluateEmployee,
    allData,
    monthOptions,
    evaluationStatic,
    selectMonth,
    selectFilter,
    onClickSearch,
    showCreateEvaluation,
    resolveApproveText,
  };
}
