<template>
  <b-overlay :show="isLoading" rounded="sm">
    <!-- <b-row class="match-height" v-if="evaluationStatic">
      <b-col xl="4" md="4" sm="6">
        <statistic-card-vertical
          icon="UserIcon"
          :statistic="evaluationStatic.allEmployee"
          statistic-title="พนักงานทั้งหมด"
          color="info"
        />
      </b-col>
      <b-col xl="4" md="4" sm="6">
        <statistic-card-vertical
          color="primary"
          icon="UserIcon"
          :statistic="evaluationStatic.successEvaluation"
          statistic-title="ประเมินแล้ว"
        />
      </b-col>
      <b-col xl="4" md="4" sm="6">
        <statistic-card-vertical
          color="success"
          icon="UserIcon"
          :statistic="evaluationStatic.waitForEvaluation"
          statistic-title="ยังไม่ประเมิน"
        />
      </b-col>
      <b-col xl="3" md="3" sm="6">
        <statistic-card-vertical
          hide-chart
          color="danger"
          icon="UserIcon"
          statistic="2.1k"
          statistic-title="รอประเมินรอบหน้า"
        />
      </b-col>
    </b-row> -->

    <b-row class="justify-content-sm-between" v-if="evaluationStatic">
      <b-col xl="9" md="9" sm="6">
        <b-tabs pills nav-class="nav-pill-primary">
          <b-tab active @click="selectFilter = 'overall'">
            <template slot="title">
              {{ $t("overall") }}
              <b-badge pill variant="danger" style="margin-left: 5px">
                {{ evaluationStatic.allEvaluation }}
              </b-badge>
            </template>
          </b-tab>
          <b-tab @click="selectFilter = 'wait_for_review'">
            <template slot="title">
              {{ $t("wait_for_evaluation") }}
              <b-badge pill variant="danger" style="margin-left: 5px">
                {{ evaluationStatic.waitForEvaluation }}
              </b-badge>
            </template>
          </b-tab>
          <b-tab @click="selectFilter = 'pending'">
            <template slot="title">
              {{ $t("pending_evaluation") }}
              <b-badge pill variant="danger" style="margin-left: 5px">
                {{ evaluationStatic.pendingEvaluation }}
              </b-badge>
            </template>
          </b-tab>
          <b-tab @click="selectFilter = 'success'">
            <template slot="title">
              {{ $t("success_evaluation") }}
              <b-badge pill variant="danger" style="margin-left: 5px">
                {{ evaluationStatic.successEvaluation }}
              </b-badge>
            </template>
          </b-tab>
        </b-tabs>
      </b-col>

      <b-col xl="2" md="2" sm="6" class="text-right">
        <!-- <b-card> </b-card> -->
        <v-select
          v-model="selectMonth"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="monthOptions"
          :reduce="(val) => val.value"
          :clearable="false"
          class="bg-white"
        />
      </b-col>
    </b-row>
    <!-- <div class="mb-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        badge="4"
        badge-classes="badge-danger badge-glow"
      >
        All
      </b-button>
      <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="flat-dark">
        Pending
      </b-button>
      <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-dark">
        Success
      </b-button>
    </div> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group class="mr-1" style="width: 70%">
                <b-form-input
                  placeholder="ค้นหาชื่อ นามสกุล เลขบัตรประชาชน"
                  v-model="searchQuery"
                  trim
                />
                <b-input-group-append>
                  <b-button variant="outline-primary" @click="onClickSearch()">
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-button
                variant="success"
                class="btn-tour-skip mr-1"
                :disabled="selectList.length === 0"
                @click="confirmBox"
              >
                {{ $t("approve_button") }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="assessedUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
      >
        <template #empty>
          <div class="d-flex justify-content-center">
            ไม่พบรายชื่อพนักงาน
            <span
              v-if="showCreateEvaluation"
              @click="createEvaluation()"
              class="text-danger"
              style="margin-left: 5px; cursor: pointer"
            >
              , สร้างรายการประเมิน
            </span>
          </div>
        </template>
        <!-- @change="selectAll"
            :checked="selectedItems.length === merchants.length" -->
        <template #head(check)>
          <b-form-checkbox
            @change="selectAll"
            :checked="
              selectList.length === allData.length && allData.length !== 0
            "
          ></b-form-checkbox>
        </template>

        <template #cell(check)="data">
          <b-form-checkbox
            @change="selectRow(data.item)"
            :checked="selectList.indexOf(data.item._id) > -1"
            :disabled="checkHideButton(data)"
          >
          </b-form-checkbox>
        </template>
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.avatar"
                :text="avatarText(data.fullName)"
                :variant="`light-${resolveEvaluationStatus(data.item.role)}`"
                :to="{
                  name: 'view-employee',
                  params: { id: data.item.employee.employee._id },
                }"
              />
            </template>
            <b-link
              :to="{
                name: 'view-employee',
                params: { id: data.item.employee.employee._id },
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.employee.employee.firstName }}
              {{ data.item.employee.employee.lastName }}
            </b-link>
            <small class="text-muted"
              >@{{ data.item.employee.employee.nationalID }}</small
            >
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveEvaluationStatus(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveEvaluationStatus(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveEvaluationText(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(approveStatus)="data">
          <b-badge
            pill
            :variant="`light-${resolveEvaluationStatus(
              data.item.approveStatus
            )}`"
            class="text-capitalize"
          >
            {{ resolveApproveText(data.item.approveStatus) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div v-if="!checkHideButton(data)">
            <b-link
              :to="{
                name: 'manage-evaluation',
                params: { id: data.item.employee.employee._id },
                query: { eva: data.item._id },
              }"
            >
              <feather-icon
                icon="FileTextIcon"
                size="16"
                class="align-middle text-danger"
                style="cursor: pointer"
                v-b-tooltip.hover.top="'Abnormal'"
              />
              {{ $t("report") }}
            </b-link>

            <feather-icon
              @click="singleEvaluate(data.item)"
              icon="CheckCircleIcon"
              size="16"
              class="align-middle ml-2 text-success"
              style="cursor: pointer"
              v-b-tooltip.hover.top="'Approve'"
            />
            <span
              style="cursor: pointer; margin-left: 5px"
              @click="singleEvaluate(data.item)"
              >{{ $t("normal") }}</span
            >
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
  BTabs,
  BTab,
  VBTooltip,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useUsersList from "./useEvaluation";
import Swal from "sweetalert2";
import dayjs from "dayjs";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    StatisticCardVertical,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    BTabs,
    BTab,
    vSelect,
    VBTooltip,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const {
      createEvaluation,
      assessedUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      refUserListTable,
      refetchData,

      // UI
      resolveEvaluationStatus,
      resolveEvaluationText,

      // Extra Filters
      isLoading,

      evaluateEmployee,
      allData,
      monthOptions,
      evaluationStatic,
      selectMonth,
      selectFilter,
      onClickSearch,
      resolveApproveText,
      showCreateEvaluation,
    } = useUsersList();

    let selectList = ref([]);

    const singleEvaluate = (data) => {
      selectList.value = [data._id];
      confirmBox();
    };
    const selectAll = () => {
      if (selectList.value.length !== allData.value.length) {
        selectList.value = allData.value
          .filter((item) => item.status === "empty")
          .map((item) => item._id);
      } else {
        selectList.value = [];
      }
    };

    const selectRow = (user) => {
      if (selectList.value.some((item) => item === user._id)) {
        selectList.value = selectList.value.filter((item) => item !== user._id);
      } else {
        selectList.value.push(user._id);
      }
    };

    const checkHideButton = (data) => {
      return (
        data.item.status !== "empty" ||
        selectMonth.value !== dayjs().format("YYYY-MM")
      );
    };
    const confirmBox = () => {
      Swal.fire({
        title: "คุณแน่ใจหรือไม่",
        text: "คุณไม่สามารถกลับมาแก้ไขได้อีก",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          evaluateEmployee(selectList.value);
        }
        if (result.value) {
          selectList.value = [];
          Swal.fire({
            icon: "success",
            title: "เสร็จสิ้น!",
            text: "การประเมินพนักงานเรียบร้อย.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    };

    return {
      createEvaluation,
      assessedUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveEvaluationStatus,
      resolveEvaluationText,

      selectAll,
      selectRow,
      selectList,
      confirmBox,

      isLoading,
      singleEvaluate,
      allData,
      monthOptions,
      evaluationStatic,
      selectMonth,
      selectFilter,
      checkHideButton,
      onClickSearch,

      showCreateEvaluation,
      resolveApproveText,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
